.navbar {
  position: relative;
  background: #1a1a1a;
  height: 130px;
  display: flex;
  padding: 0;
  font-size: 0;
  /* margin: 20px 0 0 ;
  padding: 10px 0; */
  
  
}

.navbar a{
  color: #3eb6d7;
  /* display: block; */
  position: relative;
  display: inline-block;
  margin-right: 25px;
  text-align: right;
  justify-content: space-around;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 9px 10px;
  box-shadow: 0 6px 8px 0 #3eb6d7;
  text-shadow: 2px 2px 4px #000;
}



.navbar a:hover { 
  text-shadow: 4px 4px 4px  #3eb6d7;
}

.navbar p{
  color: #c42afe;
  /* display: block; */
  float: left;
  flex-direction: column;
  font-size: 1.9rem;
  text-decoration: none;
  padding: 12px 13px;
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 300px;
  box-shadow: 0 6px 8px 0 #c42afe;
  text-shadow: 2px 2px 4px #000;

}

.nav1{
  margin-left: 200px;
  flex: left;
 
}

.nav2{
margin-left: 1px;

}



.navbar p:hover{
  text-shadow: 4px 4px 4px #c42afe;
}


@media screen and (max-width: 1500px) {
  .navbar p{
  margin-right: 30px;
  margin-left: 10px;
}



 }



@media screen and (max-width: 1275px) {
  .navbar p{
  margin-right: 25px;
  margin-left: 10px;
  
}

.nav1{
  margin-left: 25rem;
  margin-right: 0rem;
}
 
  .nav2{  
   margin-right: 5px;
 } 

 .nav3{ 
  margin-right: 5px; 
}

.nav4{ 
  margin-right: 5px;
  
}

}




@media screen and (max-width: 1224px) {
  .navbar p{
  margin-right: 15px;
}
.nav1{
  margin-left: 50px;
  flex-direction: row;
}

.nav4 {
  margin-right: 60px;
}

}

@media screen and (max-width: 1192px) {
  .navbar p{
  margin-right: 5px;
}

.nav1{
  margin-right: 10px;
  flex-direction: row;
}

}



@media screen and (max-width: 990px) {
  .navbar p{
  margin-right: 5px;
}
.nav1{
  float: left;
  flex-direction: row;
}

}


@media screen and (max-width: 876px) {
  .nav1{
    margin-left: 10px
  }

    .navbar p{
      margin: 10px;
      margin-left: 20px;
    }
  }

@media screen and (max-width: 840px) {

    .navbar p{
      margin: 10px;
      margin-left: 20px;
    }
  }



  @media screen and (max-width: 825px) {
    .nav1{
      margin-left: 1px;
    }

    .nav4{
      margin-right: 5px;

    }

}

@media screen and (max-width: 800px) {
  .navbar p{
    margin-left: 140px;
   
   
  }

  .nav1{
    float: left;
    flex: wrap;
    margin-left: 25px;

  }

  .nav3{
    float: left;
    margin-left: -80px;

  }

  .nav2{
    float:left;
    flex: wrap;
    margin-left: -70px;
  }

  .nav4{
    float: left;
    flex-direction: row;
    margin-left: -100px;
  }

}

  @media screen and (max-width: 500px) {
    .nav1{
      margin-left: 10px
    }

    .navbar p{
      margin: 10px;
      margin-left: 110px;
    }

  }
  @media screen and (max-width: 376px) {

    .nav1{
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .navbar a{
     font-size: 20px;
     padding: 8px;
     margin: 8px 0 0;
     margin-left: 10px;
   
}

    .navbar p{
      font-size: 20px;
      padding: 10px 10px;
      
      margin-right: 80px;
      margin-left: 80px;
    }

    .header h1{ 
      font-size: 70px;
    }
    
    .header img{
      height: 50px;
    }
   
} 
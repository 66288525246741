* {
  box-sizing: border-box;
  margin: 0;
  padding:0;
  
}

body{
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
 background-blend-mode: multiply,multiply;

}


.about {
  font-size: 20px;
  padding-left: 30px;
  color: white;
  text-shadow: 2px 2px 4px #000;
  margin-bottom: 175px;
  background: #2e2c2e;
  max-width: 100%;
  height: 40vh;
  
}

.about h1 {
  font-size: 60px;
  text-align: center;
  color: #3eb6d7;
  padding-bottom: 20px;
  text-shadow: 4px 4px 4px #000000;
}

.about h1:hover{
  text-shadow: 2px 2px 4px whitesmoke;
  
  
}

.about a{
  font-size: 20px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {

  .about h1{
    padding-top: 20px;

  }
}


@media screen and (max-width: 768px) {

  .footer img{
    height: 80px;

  }
}

@media screen and (max-width: 700px) {

  .footer{
    margin-top: 500px;
  
  }
}

@media screen and (max-width: 600px) {

  .footer{
    margin-top: 400px;
  
  }
}


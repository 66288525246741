*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

body{
  background: #2e2c2e;
  /* height: 60vh; */

}



.header {
  /* background: #222020; */
  height: 220px;
  background-size: cover;
  max-width: 100%;
 
}

.header h1 {
  padding-top: 70px;
  text-align: center;
  color: #3eb6d7;
  font-size: 5rem;
  text-shadow: 4px 4px 4px #000;
}

.header h1:hover{
  text-shadow: 4px 4px 4px whitesmoke;
 
}


@media screen and (max-width: 768px) {

  .header h1{
    padding-top: 70px;
    font-size: 55px;
    line-height: 3;

  }
}












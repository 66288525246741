*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

 .resume {
  font-size: 20px;
  background: #2e2c2e ;
  padding: 20px;
  padding-left: 30px;
  color: white;
  text-shadow: 2px 2px 4px #000;
  margin-bottom: 40px;
  
} 


a {
font-size: 20px;
text-decoration: none;

}

.resume h1 {

  font-size: 60px;
  text-align: center;
  color: #3eb6d7;
  text-shadow: 4px 4px 4px #000;

  
}

.resume h1:hover {
  text-shadow: 4px 4px 4px whitesmoke;
}

.profile{
  position: relative;
  padding-top: 20px;
  margin-left: 37%;
 justify-content: center;

}


@media screen and (max-width: 960px) {

  .profile{
    padding-top: 20px;
    margin-left: 20%;

  }
}



@media screen and (max-width: 768px) {

  .about h1{
    padding-top: 20px;

  }
}


@media screen and (max-width: 960px) {

  .profile{
    padding-top: 20px;
    margin-left: 15px;

  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}



.wrapper{
  /* overflow: auto; */
  display: flex;
  flex-flow: wrap;
  /* justify-content: space-around; */
  justify-content: center;
  margin-bottom: 40px;
  padding: 10px;
  
}

#project4 {
  z-index: 100;
} 


body {
  
  flex-direction: column;
  max-width: 100%;
  background: hsl(300, 2%, 18%);
  margin-left: 20px;
  margin-right: 20px;
  color: black;
  
} 


.work {
  /* margin-bottom: -40px; */
  flex-direction: column;

  
}
  
  .work h1 {
    font-size: 60px;
    text-align: center;
    color:#3eb6d7;
    text-shadow: 4px 4px 4px #000;
    
  }

.work h1:hover { 
  text-shadow: 2px 2px 4px whitesmoke;
   
}


  .screenshot {
    width: 300px;
    height: 200px;
  
    
  }

  /* .project {
    display: flex;
  } */

  div.card{
    position: relative;
    width: 300px;
    height: 200px;
    float: right;
    display: inline;
    /* flex: left; */
    flex-direction: row;
    /* justify-content: center; */
   }





 .container {
  flex-wrap: wrap;
  justify-content: center;
  

  
} 


   
  .container .card{
    margin-top: 15px;
    margin-right: 30px;
    background:  hsl(300, 2%, 18%);
    position: relative;
    margin-bottom: 180px;
    margin-left: 30px;
    /* padding-bottom: 30px; */
  
    
  }

  
  
  .container .card .face{
   width:300px;
   height: 200px; 
   transition:.4s;
   background:  hsl(300, 2%, 18%);
  
    
  }
 
  
  .container .card .face.face1{
    margin-top: -17px;
    position: relative;
    /* background: #333;  */
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    z-index: 2;
    transform: translateY(30px);
  } 
  
  .container .card:hover .face.face1{
    transform: translateY(0);
    box-shadow:
      inset 0 0 60px whitesmoke,
      inset 20px 0 80px #c42afe,
      inset -20px 0 80px #3eb6d7,
      inset 20px 0 300px rgb(0, 145, 255),
      inset -20px 0 300px #3eb6d7,
      0 0 50px #fff,
      -10px 0 80px rgb(0, 145, 255),
      10px 0 80px #3eb6d7;
     
  }
  
  
  .container .card .face.face1 .content{
    /* opacity: .2; */
    transition:  0.5s;
    text-align: center;
    background: hsl(300, 2%, 18%);
    

  }
  
  .container .card:hover .face.face1 .content{
    opacity: 1;
   
  }
  
  .container .card .face.face1 .content i{
    font-size: 3em;
    color: white;
    display: flex;
  }
  
.container .card .face.face1 .content h3{
    font-size: 1em;
    color: white;
    text-align: center;
   
 }
  
  .container .card .face.face1 .content a{
     transition: .5s;
  }
  


  .container .card .face.face2{
    position: relative;
    background: whitesmoke;
    justify-content: center;
    padding: 20px;
   box-sizing: border-box;
   box-shadow: 0 20px 50px rgba(0,0,0,.8);
   transform: translateY(-170px);
   
  
 }
 
 .container .card:hover .face.face2{
     transform: translateY(0);
 
 
 }
 
 .container .card .face.face2 .content p, a{
   font-size: 10pt;
   margin: 0 ;
   padding: 0;
   color:#333;
 }
 
 .container .card .face.face2 .content a{
   text-decoration:none;
   color: black;
   box-sizing: border-box;
   outline : 1px dashed #333;
   margin: 10px 0 0;
   padding: 5px 5px 1px;
   display: inline-block;
   padding-bottom: 10px;
 }
 
 .container .card .face.face2 .content a:hover{
   background: #333 ;
   color: whitesmoke; 
   box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
 }
